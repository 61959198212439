import store from "@/store";
import { container } from "tsyringe";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import { DexAssessmentComponent } from "@/app/infrastructures/dependencies/modules/DexAssessmentComponent";
import {
  DexAssessmentData,
  DexAssessmentList,
  DexAssessmentSummary,
  RequestDexAssessmentL1,
  RequestDexAssessmentSummary
} from "@/domain/entities/DexAssessment";
import { DexAssessmentPresenter } from "../presenters/DexAssessmentPresenter";
import {
  ResponsePayload,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";
import { DexAssesmentRequestInterface } from "@/data/payload/contracts/DexAssessmentRequest";
import { AccountController } from "./AccountController";
import { ApiRequestList, OptionsClass } from "@/domain/entities/MainApp";
import { MainAppController } from "./MainAppController";
import formatDateNumber from "@/app/infrastructures/misc/common-library/FormatDateNumber";
import { ReportController } from "./ReportController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
DexAssessmentComponent.init();

export interface DexAssessmentState {
  dexAssessmentList: DexAssessmentList;
  dexAssessmentSummary: DexAssessmentSummary;
  isLoading: boolean;
  isError: boolean;
}
@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "dex-assessment"
})
class DexAssessmentStore extends VuexModule implements DexAssessmentState {
  public isLoading = false;
  public isError = false;
  public dexAssessmentList = new DexAssessmentList();
  public dexAssessmentSummary = new DexAssessmentSummary();
  public isFirstLanding = false;
  public search = "";
  public filter = {
    reasonCode: "" as any,
    startDate: "",
    endDate: "",
    statusDisputePartner: "",
    statusFollowup: "",
    city: "" as any,
    clickFilter: false,
    orderBy: new OptionsClass(),
    sortBy: "desc" as "asc" | "desc",
    type: "",
    courierPhone: "",
    partnerId: 0,
    searchCsL1: "",
    searchCsL2: "",
    sortListCsL1: new OptionsClass(),
    sortListCsL2: new OptionsClass(),
    sortReasonDex: new OptionsClass(),
    statusReasonDex: new OptionsClass(),
    sortCourier: new OptionsClass(),
    statusCourier: new OptionsClass()
  };
  public ticketTypes: any = [];
  public isFilterData = false;
  public isMoreData = false;
  public notificationTicket = false;
  public isInfiniteScroll = false;
  public customStylesNotification: any = "";

  @Mutation
  public onResetFilter() {
    this.filter = {
      reasonCode: "",
      startDate: "",
      endDate: "",
      statusDisputePartner: "",
      statusFollowup: "",
      city: "",
      clickFilter: false,
      orderBy: new OptionsClass(),
      type: "",
      courierPhone: "",
      partnerId: 0,
      searchCsL1: "",
      searchCsL2: "",
      sortListCsL1: new OptionsClass(),
      sortListCsL2: new OptionsClass(),
      sortReasonDex: new OptionsClass(),
      statusReasonDex: new OptionsClass(),
      sortCourier: new OptionsClass(),
      statusCourier: new OptionsClass(),
      sortBy: "desc"
    };
  }

  @Action
  public getDexAssessmentList(params: RequestDexAssessmentL1) {
    this.setIsLoading(true);
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter
      .getDexAssessmentList(params)
      .then((res: DexAssessmentList) => {
        this.setError(false);
        if (!res.data.length) {
          this.setMoreData(false);
        } else {
          this.setMoreData(true);
        }
        this.setDexAssessmentList(res.data);
        return res;
      })
      .catch(() => {
        this.setError(true);
        return new DexAssessmentList();
      })
      .finally(() => this.setIsLoading(false));
  }
  @Action
  public getDexAssessmentListOnly(
    params: RequestDexAssessmentL1
  ): Promise<DexAssessmentList> {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.getDexAssessmentList(params);
  }
  @Action
  public getDexAssessmentSummary(params: RequestDexAssessmentSummary) {
    this.setIsLoading(true);
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter
      .getDexAssessmentSummary(params)
      .then((res: DexAssessmentSummary) => {
        this.setError(false);
        this.setDexAssessmentSummary(res);
      })
      .catch(() => {
        this.setError(true);
      })
      .finally(() => this.setIsLoading(false));
  }
  @Action
  public getDexAssessmentSummaryOnly(params: RequestDexAssessmentSummary) {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.getDexAssessmentSummary(params);
  }
  @Action
  public assignDexAssessment() {
    this.setIsLoading(true);
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter
      .assignDexAssessment()
      .then((res: ResponsePayload) => {
        this.setError(false);
      })
      .catch(() => {
        this.setError(true);
      })
      .finally(() => this.setIsLoading(false));
  }
  get roleType() {
    return AccountController.accountData.role_type;
  }
  get isConsole() {
    return this.roleType.includes("console");
  }
  @Action
  public async initDataTable(param = "") {
    if (param === "notification") {
      this.setNotificationTicket(true);
    }
    this.setSearch("");
    this.setFilterReasonCode("");
    this.setFirstPage();
    this.setIsFirstLanding(true);
    this.setStatusDisputePartner("");
    if (this.isConsole) {
      this.setStartDate(this.filter.startDate);
      this.setEndDate(this.filter.endDate);
    }
    this.getDexAssessmentSummary(
      new RequestDexAssessmentSummary({
        startDate: this.filter.startDate
          ? new Date(this.filter.startDate).toLocaleDateString("fr-CA")
          : "",
        endDate: this.filter.endDate
          ? new Date(this.filter.endDate).toLocaleDateString("fr-CA")
          : ""
      })
    );
    this.setInfiniteScroll(false);
    this.getDexAssessmentList(
      new RequestDexAssessmentL1({
        page: this.dexAssessmentList.pagination.page,
        limit: this.dexAssessmentList.pagination.limit,
        q: "",
        ticketTypes: "",
        startAssignedPartnerDate: this.filter.startDate
          ? new Date(this.filter.startDate).toLocaleDateString("fr-CA")
          : "",
        endAssignedPartnerDate: this.filter.endDate
          ? new Date(this.filter.endDate).toLocaleDateString("fr-CA")
          : "",
        statusFollowup: "",
        statusDisputePartner: "",
        reasonCode: ""
      })
    );
    this.setIsFirstLanding(false);
  }

  @Action
  public async fetchData() {
    this.getDexAssessmentList(
      new RequestDexAssessmentL1({
        page: 1,
        limit: this.dexAssessmentList.pagination.limit,
        q: this.search,
        ticketTypes: this.ticketTypes,
        startAssignedPartnerDate: this.filter.startDate
          ? new Date(this.filter.startDate).toLocaleDateString("fr-CA")
          : "",
        endAssignedPartnerDate: this.filter.endDate
          ? new Date(this.filter.endDate).toLocaleDateString("fr-CA")
          : "",
        statusFollowup: this.filter.statusFollowup,
        statusDisputePartner: this.filter.statusDisputePartner,
        reasonCode: this.filter.reasonCode
      })
    );
  }

  @Action
  public submitTicketDexAssesmentL1(params: {
    id: any;
    payload: DexAssesmentRequestInterface;
  }) {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.submitTicketDexAssesmentL1(params.id, params.payload);
  }

  @Action
  public getDetailDexAssesmentL1(id: any) {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.getDetailDexAssessment(id);
  }

  @Action
  public getDownloadReport(params: ApiRequestList) {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.getDownloadReport(params);
  }

  @Action
  public getSummaryTicket(params: ApiRequestList) {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.getSummaryTicket(params);
  }

  @Action
  public getSummaryTotalCs(params: ApiRequestList) {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.getSummaryTotalCs(params);
  }

  @Action
  public getSummaryTicketPercentageDisputeL2(params: ApiRequestList) {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.getSummaryTicketPercentageDisputeL2(params);
  }

  @Action
  public getTotalTicketPerDay(params: ApiRequestList) {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.getTotalTicketPerDay(params);
  }

  @Action
  public getSummaryTicketPercentage(params: ApiRequestList) {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.getSummaryTicketPercentage(params);
  }

  @Action
  public getSummaryTicketBasedRangeTime(
    params: ApiRequestList
  ): Promise<ResponsePayloadV2> {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.getSummaryTicketBasedRangeTime(params);
  }

  @Action
  public getSummaryFake(params: ApiRequestList) {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.getSummaryFake(params);
  }

  @Action
  public getAgentList(params: ApiRequestList) {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.getAgentList(params);
  }

  @Action
  public getBacklogAgent(params: ApiRequestList) {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.getBacklogAgent(params);
  }

  @Action
  public getSummaryTopDex(params: ApiRequestList) {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.getSummaryTopDex(params);
  }

  @Action
  public getSummaryBacklog(params: ApiRequestList) {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.getSummaryBacklog(params);
  }

  @Action
  public getSummaryAttempt(params: ApiRequestList) {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.getSummaryAttempt(params);
  }

  @Action
  public getSummaryByReason(params: ApiRequestList) {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.getSummaryByReason(params);
  }

  @Action
  public getDashboardConsole(params: ApiRequestList) {
    const presenter = container.resolve(DexAssessmentPresenter);
    return presenter.getDashboardConsole(params);
  }

  @Action
  public async getDownload(endpoint: string) {
    const {
      startDate,
      endDate,
      type,
      reasonCode,
      city,
      partnerId
    } = this.filter;

    try {
      MainAppController.showLoading();
      const presenter = container.resolve(DexAssessmentPresenter);
      await presenter.getDownload(
        new ApiRequestList({
          startDate: formatDateNumber(startDate),
          endDate: formatDateNumber(endDate),
          endpoint,
          ticketType: type || "l1_l2",
          reasonDex: reasonCode.toString(),
          "3lc": city?.code,
          partnerId
        })
      );

      await ReportController.onGetListDownloadReport();
      ReportController.setOpenDownloadRequest(true);
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, "Download Gagal!", () => {
          this.getDownload(type);
          MainAppController.closeErrorMessage();
        })
      );
    } finally {
      MainAppController.closeLoading();
      this.filter.partnerId = 0;
    }
  }

  @Mutation
  public setDexAssessmentList(param: DexAssessmentData[]) {
    if (this.dexAssessmentList.pagination.page > 1) {
      this.dexAssessmentList.data = [...this.dexAssessmentList.data, ...param];
    } else {
      this.dexAssessmentList.data = param;
    }
  }

  @Mutation
  public setDexAssessmentSummary(param: DexAssessmentSummary) {
    this.dexAssessmentSummary = param;
  }

  @Mutation
  public setNotificationTicket(boolean: boolean) {
    this.notificationTicket = boolean;
  }

  @Mutation
  public setFilterReasonCode(val: string) {
    this.filter.reasonCode = val;
  }

  @Mutation
  public setCustomStyleNotification(val: any) {
    this.customStylesNotification = val;
  }

  @Mutation
  public setError(param: boolean) {
    this.isError = param;
  }

  @Mutation
  public setIsLoading(param: boolean) {
    this.isLoading = param;
  }

  @Mutation
  public setTicketTypes(param: any) {
    param = param.toString();
    this.ticketTypes = param;
  }
  @Mutation
  public setStatusFollowup(param: any) {
    this.filter.statusFollowup = param;
  }
  @Mutation
  public setStatusDisputePartner(param: any) {
    param = param.toString();
    this.filter.statusDisputePartner = param;
  }
  @Mutation
  public setIsFirstLanding(param: boolean) {
    this.isFirstLanding = param;
  }
  @Mutation
  public async setSearch(value: string) {
    this.search = value;
  }
  @Action
  public async searchAction(value: string) {
    this.setSearch(value);
    await this.fetchData();
    this.setFirstPage();
  }

  @Action
  public async clear() {
    await this.searchAction("");
  }

  @Action
  public setFirstPage() {
    this.dexAssessmentList.pagination.page = 1;
  }

  @Mutation
  public setStartDate(value: any) {
    this.filter.startDate = value;
  }

  @Mutation
  public setEndDate(value: any) {
    this.filter.endDate = value;
  }

  @Mutation
  public setIsFilterData(value: boolean) {
    this.isFilterData = value;
  }
  @Mutation
  public setMoreData(value: boolean) {
    this.isMoreData = value;
  }
  @Mutation
  public setInfiniteScroll(value: boolean) {
    this.isInfiniteScroll = value;
  }

  public dashboardTabs = [
    {
      path: "dex-assessment-tab",
      name: "DEX Asesmen",
      title: "Insight Tiket DEX"
    },
    {
      path: "dex-dispute-l2-tab",
      name: "DEX Sanggah L2",
      title: "Insight Tiket DEX Sanggah L2"
    },
    {
      path: "performa-agent-tab",
      name: "Performa Agen",
      title: "Insight Tiket DEX dari Performa Agen CS L1 & L2"
    }
  ];
  public dashboardCurrentTabActive = "dex-assessment-tab";
  @Mutation
  public setDashboardCurrentTabActive(value: string) {
    this.dashboardCurrentTabActive = value;
  }

  donutChartData: any[] = [];
  @Mutation
  public setDonutChartData(value: any[]) {
    this.donutChartData = value;
  }

  @Action
  public onMappingDonutChartData(data: any): void {
    for (const [key] of Object.entries(data)) {
      this.donutChartData.forEach((e: any, i: number) => {
        if (e.key === key) {
          this.onMappingDonutChartValue({ data, index: i });
        }
      });
    }
  }
  @Mutation
  public onMappingDonutChartValue(params: { data: any; index: number }): void {
    const { data, index } = params;

    /* donut chart */
    if (this.donutChartData[index]?.data) {
      const tempData = [];
      const donutData: any = this.donutChartData[index].dataKey;

      for (const item of donutData) {
        tempData.push(data[item]);
      }

      this.donutChartData[index].data = tempData;
    }
    /* end donut chart */

    /* total ticket */
    const total = this.donutChartData[index].total;
    if (total) {
      this.donutChartData[index].total.value = data[total.key];
    }

    const percentage = this.donutChartData[index].percentage;
    if (percentage) {
      this.donutChartData[index].percentage.value = data[percentage.key];
    }
    /* end total ticket */
  }
}

export const DexAssessmentController = getModule(DexAssessmentStore);
