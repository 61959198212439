import { container } from "tsyringe";
import ApiService from "../../services/ApiService";
import { DexAssessmentApiRepository } from "../../repositories/api/DexAssessmentApiRepository";
import { DexAssessmentMapper } from "@/data/persistences/mappers/DexAsessmentMapper";
import { DexAssessmentEndpoint } from "../../endpoints/horde/DexAssessmentEndpoint";
import { DexAssessmentPresenter } from "@/app/ui/presenters/DexAssessmentPresenter";

export class DexAssessmentComponent {
  public static init() {
    container.register<DexAssessmentApiRepository>(DexAssessmentApiRepository, {
      useFactory: d => {
        return new DexAssessmentApiRepository(
          d.resolve(ApiService),
          d.resolve(DexAssessmentMapper),
          d.resolve(DexAssessmentEndpoint)
        );
      }
    });
    container.register<DexAssessmentMapper>(DexAssessmentMapper, {
      useClass: DexAssessmentMapper
    });
    container.register<DexAssessmentPresenter>(DexAssessmentPresenter, {
      useFactory: d => {
        return new DexAssessmentPresenter(
          d.resolve(DexAssessmentApiRepository)
        );
      }
    });
  }
}
